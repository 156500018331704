import React, { memo, useCallback, useState, FC } from 'react';
import moment from 'moment';
import { t } from 'i18n-js';

import { AiOutlineHistory } from 'react-icons/ai';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { useAppDispatch } from 'hooks';

import { DeviceHistoryItem } from 'store/devices/model';
import { getHistory } from 'store/devices/thunks';

interface Props {
  id: string;
  title: string;
}

const ItemHistory: FC<Props> = ({ id, title }) => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [history, setHistory] = useState<DeviceHistoryItem[]>([]);

  const handleOpen = useCallback(async () => {
    const history = await dispatch(getHistory(id));
    setHistory(history);
    setShowModal(true);
  }, [dispatch, id]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setHistory([]);
  }, []);

  return (
    <>
      <Button
        className="wow-devices-item-btn"
        onClick={handleOpen}
        variant="success"
        size="sm">
        <AiOutlineHistory />
      </Button>

      <Modal
        className="wow-devices-history-modal"
        show={showModal}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('devices.history', { model: title })}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped hover responsive size="sm">
            <thead>
              <tr>
                <th>{t('devices.date')}</th>
                <th>{t('devices.author')}</th>
                <th>{t('devices.status')}</th>
              </tr>
            </thead>
            <tbody>
              {history.map((item, i) => (
                <tr key={i}>
                  <td>{moment(item.date.toDate()).format('D MMM HH:mm')}</td>
                  <td>{item.author}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ItemHistory);
