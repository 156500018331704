import React, { memo, useCallback, useState, FC } from 'react';
import { t } from 'i18n-js';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Navbar from 'react-bootstrap/Navbar';

import { TbFilter, TbFilterX } from 'react-icons/tb';

import { useAppDispatch, useAppSelector } from 'hooks';

import {
  selectModels,
  selectPublishers,
  selectOsVersions,
  selectFilterIsSet,
  setFilterModel,
  setFilterPublisher,
  setFilterOsVersion,
  resetFilter
} from 'store/devices';

const Filter: FC = () => {
  const dispatch = useAppDispatch();

  const { filterModel, filterPublisher, filterOsVersion } = useAppSelector(
    (state) => state.devices
  );

  const models = useAppSelector(selectModels);
  const publishers = useAppSelector(selectPublishers);
  const osVersions = useAppSelector(selectOsVersions);

  const filterIsSet = useAppSelector(selectFilterIsSet);

  const [model, setModel] = useState<string>(filterModel);
  const [publisher, setPublisher] = useState<string>(filterPublisher);
  const [osVersion, setOsVersion] = useState<string>(filterOsVersion);

  const handleModelChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setModel(e.target.value);
      dispatch(setFilterModel(e.target.value));
    },
    [dispatch]
  );

  const handlePublisherChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setPublisher(e.target.value);
      dispatch(setFilterPublisher(e.target.value));
    },
    [dispatch]
  );

  const handleOsVersionChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setOsVersion(e.target.value);
      dispatch(setFilterOsVersion(e.target.value));
    },
    [dispatch]
  );

  const handleResetPress = useCallback(() => {
    setModel('');
    setPublisher('');
    setOsVersion('');
    dispatch(resetFilter());
  }, [dispatch]);

  return (
    <Navbar>
      <InputGroup>
        <Form.Select onChange={handleModelChange} value={model} size="sm">
          <option value="">{t('devices.filter.any_model')}</option>
          {models.map((model) => (
            <option value={model} key={model}>
              {model}
            </option>
          ))}
        </Form.Select>

        <Form.Select
          onChange={handlePublisherChange}
          value={publisher}
          size="sm">
          <option value="">{t('devices.filter.any_publisher')}</option>
          {publishers.map((publisher) => (
            <option value={publisher} key={publisher}>
              {publisher}
            </option>
          ))}
        </Form.Select>

        <Form.Select
          onChange={handleOsVersionChange}
          value={osVersion}
          size="sm">
          <option value="">{t('devices.filter.any_os')}</option>
          {osVersions.map((osVersion) => (
            <option value={osVersion} key={osVersion}>
              {osVersion}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="success"
          disabled={!filterIsSet}
          onClick={handleResetPress}>
          {filterIsSet ? <TbFilterX /> : <TbFilter />}
        </Button>
      </InputGroup>
    </Navbar>
  );
};

export default memo(Filter);
