import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import _ from 'lodash';

import { initialState, Cols, Device } from './model';

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setFilterModel(state, action: PayloadAction<string>) {
      state.filterModel = action.payload;
    },
    setFilterPublisher(state, action: PayloadAction<string>) {
      state.filterPublisher = action.payload;
    },
    setFilterOsVersion(state, action: PayloadAction<string>) {
      state.filterOsVersion = action.payload;
    },
    resetFilter(state) {
      state.filterModel = '';
      state.filterPublisher = '';
      state.filterOsVersion = '';
    },
    setList(state, action: PayloadAction<Device[]>) {
      state.list = action.payload;
    },
    addPinned(state, action: PayloadAction<string>) {
      state.pinned = _.uniq(_.concat(state.pinned, action.payload));
    },
    togglePinned(state, action: PayloadAction<string>) {
      if (_.includes(state.pinned, action.payload)) {
        _.pull(state.pinned, action.payload);
      } else {
        state.pinned.push(action.payload);
      }
    },
    setSortCol(state, action: PayloadAction<Cols>) {
      state.sortCol = action.payload;
    },
    setSortRev(state) {
      state.sortRev = !state.sortRev;
    }
  }
});

export const {
  setFilterModel,
  setFilterPublisher,
  setFilterOsVersion,
  resetFilter,
  setList,
  addPinned,
  togglePinned,
  setSortCol,
  setSortRev
} = devicesSlice.actions;

export default persistReducer(
  {
    key: devicesSlice.name,
    storage: storage
  },
  devicesSlice.reducer
);

export * from './selectors';
