import React, { memo, FC } from 'react';

import Spinner from 'react-bootstrap/Spinner';

import './Splash.css';

const Splash: FC = () => {
  return (
    <div className="wow-splash-wr">
      <Spinner animation="border" variant="success" />
    </div>
  );
};

export default memo(Splash);
