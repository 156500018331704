import React, { useEffect, FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';

import { init as initI18n } from 'i18n';
import { init as initUser } from 'store/user/thunks';

import { Panel, RequireAuth, Splash } from 'components';
import { Devices, Home, Page404 } from 'screens';

export const App: FC = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => !state.user.fetched);

  useEffect(() => {
    initI18n();
    dispatch(initUser());
  }, [dispatch]);

  return loading ? (
    <Splash />
  ) : (
    <Router>
      <Panel />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/devices"
          element={
            <RequireAuth>
              <Devices />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
};

export default App;
