import React, { memo, FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';

import { selectIsAuthed } from 'store/user';

interface Props {
  children: JSX.Element;
}

const RequireAuth: FC<Props> = ({ children }) => {
  const isAuthed = useAppSelector(selectIsAuthed);

  return isAuthed ? children : <Navigate to="/" replace />;
};

export default memo(RequireAuth);
