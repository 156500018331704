import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  FC
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { t } from 'i18n-js';

import {
  AiFillCheckSquare,
  AiFillCloseSquare,
  AiFillEdit
} from 'react-icons/ai';

import { useAppDispatch } from 'hooks';

import { setStatus } from 'store/devices/thunks';

interface Props {
  id: string;
  status: string;
}

const ItemStatus: FC<Props> = ({ id, status }) => {
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [value, setValue] = useState<string>(status);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    setValue(status);
  }, [status]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    []
  );

  const handleSave = useCallback(() => {
    if (edit) {
      inputRef.current?.blur();
      setEdit(false);

      if (value !== status) {
        dispatch(setStatus(id, value));
      }
    } else {
      setEdit(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [dispatch, edit, id, status, value]);

  const handleCancel = useCallback(() => {
    inputRef.current?.blur();
    setEdit(false);
    setValue(status);
  }, [status]);

  return status ? (
    <div className="wow-devices-status-wr">
      <TextareaAutosize
        ref={inputRef}
        className="wow-devices-status-input"
        value={value}
        disabled={!edit}
        onChange={handleChange}
      />

      <div className="wow-devices-status-controls">
        {edit ? (
          <span
            className="wow-devices-status-button me-1"
            onClick={handleCancel}>
            <AiFillCloseSquare />
          </span>
        ) : null}

        <span className="wow-devices-status-button" onClick={handleSave}>
          {edit ? <AiFillCheckSquare /> : <AiFillEdit />}
        </span>
      </div>
    </div>
  ) : (
    <span className="wow-devices-status-text">
      {t('devices.device_available')}
    </span>
  );
};

export default memo(ItemStatus);
