import {
  addDoc,
  collection,
  doc,
  enableIndexedDbPersistence,
  getDocs,
  limit,
  orderBy,
  onSnapshot,
  setDoc,
  query,
  updateDoc,
  where,
  Timestamp
} from 'firebase/firestore';
import { t } from 'i18n-js';
import _ from 'lodash';

import { db, Collections } from 'api/firebase';
import { AppThunk } from 'store';

import { Device, DeviceHistoryItem } from './model';
import { generateDeviceId } from './helpers';
import { setList } from '.';

import newDevices from './import.json';

let devicesSub = () => {};

export const init = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await enableIndexedDbPersistence(db);
  } catch (err) {
    console.warn(err);
  }

  devicesSub = onSnapshot(
    query(collection(db, Collections.Devices)),
    (querySnapshot) => {
      const devices: Device[] = [];

      querySnapshot.forEach((doc) => {
        devices.push({
          id: doc.id,
          ...doc.data()
        } as Device);
      });

      dispatch(setList(devices));
    }
  );

  // dispatch(importDevices());
};

export const unsubscribe = (): AppThunk => () => {
  devicesSub?.();
};

export const takeDevice =
  (id: string, takeaway?: boolean): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { displayName, email } = getState().user.authData;

    await dispatch(
      setStatus(
        id,
        t(`devices.${takeaway ? 'took_away' : 'took'}`, {
          name: displayName || email
        })
      )
    );
  };

export const returnDevice =
  (id: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(setStatus(id, ''));
  };

export const setStatus =
  (id: string, status: string): AppThunk<Promise<void>> =>
  async (_dispatch, getState) => {
    const { displayName, email } = getState().user.authData;

    await updateDoc(doc(db, Collections.Devices, id), {
      status
    });

    await addDoc(collection(db, Collections.History), {
      author: displayName || email,
      date: Timestamp.fromDate(new Date()),
      device: id,
      status: status || t('devices.device_available')
    });
  };

export const getHistory =
  (id: string): AppThunk<Promise<DeviceHistoryItem[]>> =>
  async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, Collections.History),
        where('device', '==', id),
        orderBy('date', 'desc'),
        limit(10)
      )
    );

    const history: DeviceHistoryItem[] = [];

    querySnapshot.forEach((doc) => {
      history.push(doc.data() as DeviceHistoryItem);
    });

    return history;
  };

export const importDevices = (): AppThunk => () => {
  if (newDevices?.length) {
    _.forEach(newDevices, (device) => {
      const id = generateDeviceId(device);
      setDoc(doc(db, Collections.Devices, id), device, { merge: true });
    });

    console.info(`Imported ${newDevices.length} new devices`);
  }
};
