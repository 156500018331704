import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState, AuthData } from './model';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthData(state, action: PayloadAction<AuthData>) {
      state.authData = action.payload;
      state.fetched = true;
    },
    resetAuthData(state) {
      state.authData = initialState.authData;
      state.fetched = true;
    }
  }
});

export const { setAuthData, resetAuthData } = userSlice.actions;

export default userSlice.reducer;

export * from './selectors';
