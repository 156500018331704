interface UserState {
  authData: AuthData;
  fetched: boolean;
}

export interface AuthData {
  displayName: string;
  email: string;
  uid: string;
}

export const initialState: UserState = {
  authData: {
    displayName: '',
    email: '',
    uid: ''
  },
  fetched: false
};
