import React, { memo, useEffect, useMemo, FC } from 'react';
import _ from 'lodash';
import { t } from 'i18n-js';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Cols, Device, DeviceProps } from 'store/devices/model';
import { init as initDevices } from 'store/devices/thunks';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import { Filter, Item, TableTitle } from './components';

import './Devices.css';

interface DevicePinned extends Device {
  pinned: boolean;
}

export const Devices: FC = () => {
  const dispatch = useAppDispatch();

  const {
    filterModel,
    filterPublisher,
    filterOsVersion,
    list,
    pinned,
    sortCol,
    sortRev
  } = useAppSelector((state) => state.devices);

  const devicesPinned = useMemo(
    (): DevicePinned[] =>
      list.map((device) => ({
        ...device,
        pinned: _.includes(pinned, device.id)
      })),
    [list, pinned]
  );

  const devices = useMemo(
    (): DevicePinned[] =>
      _.orderBy(
        _.filter(
          devicesPinned,
          (device) =>
            (!filterModel || device.model === filterModel) &&
            (!filterPublisher || device.publisher === filterPublisher) &&
            (!filterOsVersion || device.os === filterOsVersion)
        ),
        [
          Cols.Pinned,
          sortCol,
          Cols.Publisher,
          Cols.Model,
          DeviceProps.ModelSuffix
        ],
        ['desc', sortRev ? 'desc' : 'asc']
      ),
    [
      devicesPinned,
      filterModel,
      filterOsVersion,
      filterPublisher,
      sortCol,
      sortRev
    ]
  );

  useEffect(() => {
    dispatch(initDevices());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <div className="my-3">
        <Filter />

        <Table
          className="wow-devices-table"
          striped
          bordered
          hover
          responsive
          size="sm">
          <thead>
            <tr>
              <th className="wow-devices-th wow-devices-th-index">#</th>
              <TableTitle col={Cols.Model} />
              <TableTitle col={Cols.Publisher} />
              <TableTitle col={Cols.Status} />
              <th className="wow-devices-th wow-devices-th-actions">
                {t('devices.cols.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {devices.map((device, i) => (
              <Item device={device} index={i} key={device.id} />
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default memo(Devices);
