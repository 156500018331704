import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA2brndlaVaam2wPDE6rFwGG-2BWkBG4PE',
  authDomain: 'wowdevices-e2e72.firebaseapp.com',
  projectId: 'wowdevices-e2e72',
  storageBucket: 'wowdevices-e2e72.appspot.com',
  messagingSenderId: '521772771702',
  appId: '1:521772771702:web:ba61df3cbf56978fc53257'
};

// App
export const app = initializeApp(firebaseConfig);

// Auth
export const auth = getAuth(app);

// Firestore
export const db = getFirestore(app);

export enum Collections {
  Devices = 'devices',
  History = 'history'
}
