import i18n from 'i18n-js';

const locale = 'en';

export const init = () => {
  i18n.locale = locale;
  i18n.defaultLocale = locale;
  i18n.fallbacks = true;
  i18n.missingTranslation = (key) => key;

  i18n.translations = {
    [locale]: require('./en.json')
  };
};
