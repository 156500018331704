import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from 'store';

export const selectModels = createSelector(
  [(state: RootState) => state.devices.list],
  (devices) => _.sortBy(_.uniq(_.map(devices, (device) => device.model)))
);

export const selectPublishers = createSelector(
  [(state: RootState) => state.devices.list],
  (devices) => _.sortBy(_.uniq(_.map(devices, (device) => device.publisher)))
);

export const selectOsVersions = createSelector(
  [(state: RootState) => state.devices.list],
  (devices) => _.sortBy(_.uniq(_.map(devices, (device) => device.os)))
);

export const selectFilterIsSet = createSelector(
  [
    (state: RootState) => state.devices.filterModel,
    (state: RootState) => state.devices.filterPublisher,
    (state: RootState) => state.devices.filterOsVersion
  ],
  (filterModel, filterPublisher, filterOsVersion) =>
    !!filterModel || !!filterPublisher || !!filterOsVersion
);
