import { Timestamp } from 'firebase/firestore';

interface DevicesState {
  filterModel: string;
  filterPublisher: string;
  filterOsVersion: string;
  list: Device[];
  pinned: string[];
  sortCol: Cols;
  sortRev: boolean;
}

export enum DeviceProps {
  Account = 'account',
  AccountPwd = 'account_pwd',
  Aid = 'aid',
  Desc = 'desc',
  Id = 'id',
  Imei1 = 'imei1',
  Imei2 = 'imei2',
  InvNumber = 'inv_number',
  Os = 'os',
  Model = 'model',
  ModelSuffix = 'model_suffix',
  Publisher = 'publisher',
  Region = 'region',
  ScreenInches = 'screen_inches',
  ScreenPixels = 'screen_pixels',
  SerialNumber = 'serial_number',
  Status = 'status',
  Udid = 'udid'
}

export enum Cols {
  Actions = 'actions',
  Model = 'model',
  Pinned = 'pinned',
  Publisher = 'publisher',
  Status = 'status'
}

export interface ImportedDevice {
  [DeviceProps.Account]: string;
  [DeviceProps.AccountPwd]: string;
  [DeviceProps.Aid]: string;
  [DeviceProps.Desc]: string;
  [DeviceProps.Imei1]: number | string;
  [DeviceProps.Imei2]: number | string;
  [DeviceProps.InvNumber]: number | string;
  [DeviceProps.Os]: string;
  [DeviceProps.Model]: string;
  [DeviceProps.ModelSuffix]: string;
  [DeviceProps.Publisher]: string;
  [DeviceProps.Region]: string;
  [DeviceProps.ScreenInches]: string;
  [DeviceProps.ScreenPixels]: string;
  [DeviceProps.SerialNumber]: string;
  [DeviceProps.Udid]: string;
}

export interface Device extends ImportedDevice {
  [DeviceProps.Id]: string;
  [DeviceProps.Status]: string;
}

export interface DeviceHistoryItem {
  author: string;
  date: Timestamp;
  device: string;
  status: string;
}

export const initialState: DevicesState = {
  filterModel: '',
  filterPublisher: '',
  filterOsVersion: '',
  list: [],
  pinned: [],
  sortCol: Cols.Model,
  sortRev: false
};
