import React, { memo, useMemo, FC } from 'react';
import { t } from 'i18n-js';
import _ from 'lodash';

import { DeviceProps } from 'store/devices/model';

import ItemClipboard from './ItemClipboard';

const lowerCaseProps: DeviceProps[] = [DeviceProps.Aid, DeviceProps.Udid];

interface Props {
  prop: DeviceProps;
  value: number | string;
}

const ItemInfoLine: FC<Props> = ({ prop, value }) => {
  const localValue = useMemo(
    () =>
      _.includes(lowerCaseProps, prop) ? _.toLower(_.toString(value)) : value,
    [prop, value]
  );

  return value ? (
    <tr>
      <td className="fw-bold">{t(`devices.props.${prop}`)}</td>
      <td>{localValue}</td>
      <ItemClipboard text={localValue} />
    </tr>
  ) : null;
};

export default memo(ItemInfoLine);
