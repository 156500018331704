import { Device } from 'store/devices/model';

export const getDeviceInfoStr = (device: Device): string => {
  let result = `s/n: *${device.serial_number.slice(-5)}`;

  if (device.inv_number) {
    result += ` | inv: ${device.inv_number}`;
  }

  if (device.model_suffix) {
    result += ` | ${device.model_suffix}`;
  }

  return result;
};
