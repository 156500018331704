import React, { memo, useCallback, useMemo, FC } from 'react';
import _ from 'lodash';

import { AiFillPushpin, AiOutlinePushpin } from 'react-icons/ai';

import Button from 'react-bootstrap/Button';

import { useAppDispatch, useAppSelector } from 'hooks';

import { togglePinned } from 'store/devices';

interface Props {
  id: string;
}

const ItemPin: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();

  const pinnedDevices = useAppSelector((state) => state.devices.pinned);

  const isPinned = useMemo(
    () => _.includes(pinnedDevices, id),
    [id, pinnedDevices]
  );

  const handleClick = useCallback(() => {
    dispatch(togglePinned(id));
  }, [dispatch, id]);

  return (
    <Button
      onClick={handleClick}
      variant={isPinned ? 'success' : 'secondary'}
      size="sm">
      {isPinned ? <AiFillPushpin /> : <AiOutlinePushpin />}
    </Button>
  );
};

export default memo(ItemPin);
