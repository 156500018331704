import React, { memo, useCallback, FC } from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { t } from 'i18n-js';

import { useAppDispatch, useAppSelector } from 'hooks';

import { setSortCol, setSortRev } from 'store/devices';
import { Cols } from 'store/devices/model';

interface Props {
  col: Cols;
}

const TableTitle: FC<Props> = ({ col }) => {
  const dispatch = useAppDispatch();

  const { sortCol, sortRev } = useAppSelector((state) => state.devices);

  const handleClick = useCallback(() => {
    if (sortCol === col) {
      dispatch(setSortRev());
    } else {
      dispatch(setSortCol(col));
    }
  }, [col, dispatch, sortCol]);

  return (
    <th
      className={`wow-devices-th wow-devices-th-${col} wow-devices-th-sortable`}
      onClick={handleClick}>
      <span className="me-1">{t(`devices.cols.${col}`)}</span>
      {sortCol !== col ? <FaSort /> : null}
      {sortCol === col && !sortRev ? <FaSortDown /> : null}
      {sortCol === col && sortRev ? <FaSortUp /> : null}
    </th>
  );
};

export default memo(TableTitle);
