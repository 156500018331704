import React, { memo, useCallback, useMemo, FC } from 'react';
import { t } from 'i18n-js';

import { useAppDispatch, useAppSelector } from 'hooks';

import { Device } from 'store/devices/model';
import { takeDevice, returnDevice } from 'store/devices/thunks';

import Button from 'react-bootstrap/Button';

import { getDeviceInfoStr } from '../helpers';

import ItemHistory from './ItemHistory';
import ItemInfo from './ItemInfo';
import ItemPin from './ItemPin';
import ItemStatus from './ItemStatus';

interface Props {
  device: Device;
  index: number;
}

const Item: FC<Props> = ({ device, index }) => {
  const dispatch = useAppDispatch();

  const displayName = useAppSelector(state => state.user.authData.displayName);

  const handleTake = useCallback(() => {
    dispatch(takeDevice(device.id));
  }, [device.id, dispatch]);

  const handleTakeaway = useCallback(() => {
    dispatch(takeDevice(device.id, true));
  }, [device.id, dispatch]);

  const handleReturn = useCallback(() => {
    dispatch(returnDevice(device.id));
  }, [device.id, dispatch]);

  const deviceInfo = useMemo(() => getDeviceInfoStr(device), [device]);
  const isUsersDevice = useMemo(() => !!device.status?.includes(displayName), [device.status, displayName])

  return (
    <tr key={device.id} className={isUsersDevice ? 'wow-devices-item-selected' : ''}>
      <td>{index + 1}</td>

      <td>
        <div className="wow-devices-item-model">
          <span className="wow-devices-item-model-text me-1">
            <span>{device.model}</span>
            <span>{deviceInfo}</span>
          </span>
          <span>
            <ItemInfo data={device} />
            <ItemHistory
              id={device.id}
              title={`${device.model} ${device.model_suffix}`}
            />
          </span>
        </div>
      </td>

      <td>{device.publisher}</td>

      <td>
        <ItemStatus id={device.id} status={device.status} />
      </td>

      <td>
        <Button
          onClick={handleTake}
          disabled={!!device.status}
          variant="success"
          size="sm"
          className="me-1">
          {t('devices.take')}
        </Button>

        <Button
          onClick={handleTakeaway}
          disabled={!!device.status}
          variant="success"
          size="sm"
          className="me-1">
          {t('devices.take_away')}
        </Button>

        <Button
          onClick={handleReturn}
          disabled={!device.status}
          variant="success"
          size="sm"
          className="me-1">
          {t('devices.return')}
        </Button>

        <ItemPin id={device.id} />
      </td>
    </tr>
  );
};

export default memo(Item);
