import React, { memo, useCallback, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { t } from 'i18n-js';

import { useAppDispatch, useAppSelector } from 'hooks';

import { selectIsAuthed } from 'store/user';
import { signIn } from 'store/user/thunks';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

export const Home: FC = () => {
  const dispatch = useAppDispatch();

  const isAuthed = useAppSelector(selectIsAuthed);

  const handleSignIn = useCallback(() => {
    dispatch(signIn());
  }, [dispatch]);

  return isAuthed ? (
    <Navigate to="/devices" replace />
  ) : (
    <Container>
      <div className="my-5 text-center">
        <h4 className="mb-5">{t('user.banner')}</h4>

        <Button onClick={handleSignIn} variant="success" size="lg">
          {t('user.sign_in')}
        </Button>
      </div>
    </Container>
  );
};

export default memo(Home);
