import React, { memo, FC } from 'react';
import { t } from 'i18n-js';

import Container from 'react-bootstrap/Container';

export const Page404: FC = () => {
  return (
    <Container>
      <div className="my-5 text-center">
        <h4 className="mb-5">{t('app.page_not_found')}</h4>
      </div>
    </Container>
  );
};

export default memo(Page404);
