import React, { memo, useCallback, useState, FC } from 'react';
import { t } from 'i18n-js';

import { AiOutlineInfoCircle } from 'react-icons/ai';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { Device, DeviceProps } from 'store/devices/model';

import ItemInfoLine from './ItemInfoLine';

const props: DeviceProps[] = [
  DeviceProps.Model,
  DeviceProps.Publisher,
  DeviceProps.SerialNumber,
  DeviceProps.InvNumber,
  DeviceProps.Desc,
  DeviceProps.Region,
  DeviceProps.Imei1,
  DeviceProps.Imei2,
  DeviceProps.ScreenInches,
  DeviceProps.ScreenPixels,
  DeviceProps.Os,
  DeviceProps.Account,
  DeviceProps.AccountPwd,
  DeviceProps.Udid,
  DeviceProps.Aid
];

interface Props {
  data: Device;
}

const ItemInfo: FC<Props> = ({ data }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOpen = useCallback(async () => {
    setShowModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <Button
        className="wow-devices-item-btn me-1"
        onClick={handleOpen}
        variant="success"
        size="sm">
        <AiOutlineInfoCircle />
      </Button>

      <Modal
        className="wow-devices-info-modal"
        show={showModal}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('devices.info', { model: `${data.model} ${data.model_suffix}` })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped hover responsive size="sm">
            <tbody>
              {props.map((prop, i) => (
                <ItemInfoLine prop={prop} value={data[prop]} key={prop} />
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ItemInfo);
