import React, { memo, useCallback, FC } from 'react';
import { t } from 'i18n-js';

import { useAppDispatch, useAppSelector } from 'hooks';

import { signIn, signOut } from 'store/user/thunks';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import packageJson from './package.alias.json';

const Panel: FC = () => {
  const dispatch = useAppDispatch();

  const email = useAppSelector((state) => state.user.authData.email);

  const handleSignIn = useCallback(() => {
    dispatch(signIn());
  }, [dispatch]);

  const handleSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt={t('app.name')}
            title={`${t('app.name')} v${packageJson.version}`}
            src="/favicon.png"
            width="30"
            height="30"
            className="d-inline-block align-top me-2"
          />
          <span>{t('app.name')}</span>
        </Navbar.Brand>
        <Navbar.Text>
          {email ? (
            <>
              <span className="d-none d-sm-inline me-3">{email}</span>
              <Button onClick={handleSignOut} variant="success" size="sm">
                {t('user.sign_out')}
              </Button>
            </>
          ) : (
            <Button onClick={handleSignIn} variant="success" size="sm">
              {t('user.sign_in')}
            </Button>
          )}
        </Navbar.Text>
      </Container>
    </Navbar>
  );
};

export default memo(Panel);
