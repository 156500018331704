import React, { memo, useCallback, FC } from 'react';

import { AiFillCopy } from 'react-icons/ai';

interface Props {
  text: string | number;
}

const ItemClipboard: FC<Props> = ({ text }) => {
  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(text.toString());
  }, [text]);

  return (
    <td>
      <span className="wow-devices-copy" onClick={handleClick}>
        <AiFillCopy />
      </span>
    </td>
  );
};

export default memo(ItemClipboard);
